import * as React from 'react'
import { useState, useEffect } from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import * as Icon from 'react-feather'

import logo from '@base/images/common/logo.svg'

type MenuItem = Pick<
  GatsbyTypes.WpMenuItem,
  'url' | 'label' | 'id' | 'parentId'
>

type TreeNode = [MenuItem, TreeNode[]]

const buildMenuTree = (menus: readonly MenuItem[]): TreeNode[] => {
  const rootMenu = { id: 'root', label: '', parentId: '', url: '' }
  const result: { [key: string]: TreeNode } = {
    root: [rootMenu, []],
  }

  menus.forEach(menu => {
    const node: TreeNode = [menu, []]
    result[menu.id] = node
    result[menu.parentId || 'root'][1].push(node)
  })

  return result['root'][1]
}

const MenuBar: React.FC<{
  treeNodes: TreeNode[]
  toggleHeader: (event: React.MouseEvent<HTMLAnchorElement>) => void
}> = ({ treeNodes, toggleHeader }) => {
  return (
    <ul className="navbar-nav ms-auto">
      {treeNodes.map(node => (
        <Menu
          key={node[0].id}
          treeNode={node}
          isRoot={true}
          toggleHeader={toggleHeader}
        />
      ))}
    </ul>
  )
}

const MenuLink: React.FC<
  React.PropsWithChildren<{
    to: string
    onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void
  }>
> = ({ children, to, onClick }) => {
  const props = {
    className: 'nav-link',
    onClick,
  }

  if (/^https?:\/\//.test(to)) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )
  } else {
    return (
      <Link to={to} activeClassName="active" {...props}>
        {children}
      </Link>
    )
  }
}

const Menu: React.FC<{
  treeNode: TreeNode
  isRoot?: boolean
  toggleHeader: (event: React.MouseEvent<HTMLAnchorElement>) => void
}> = ({ treeNode, toggleHeader, isRoot = false }) => {
  const menuItem = treeNode[0]
  const childNodes = treeNode[1]
  const to = menuItem.url ?? '#'

  const onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isRoot) toggleHeader(event)
    if (to === '#') event.preventDefault()
  }

  return (
    <li className="nav-item">
      {menuItem && (
        <MenuLink to={to} onClick={onClick}>
          {menuItem.label} {childNodes.length > 0 && <Icon.ChevronDown />}
        </MenuLink>
      )}
      {childNodes.length > 0 && (
        <ul className="dropdown-menu">
          {childNodes.map(node => (
            <Menu
              key={node[0].id}
              treeNode={node}
              toggleHeader={toggleHeader}
            />
          ))}
        </ul>
      )}
    </li>
  )
}

const Header: React.FC = () => {
  const {
    allWpMenuItem: { menuItems },
  } = useStaticQuery<GatsbyTypes.MenuItemsQuery>(graphql`query MenuItems {
  allWpMenuItem(
    filter: {menu: {node: {name: {eq: "Main Menu"}}}}
    sort: {order: ASC}
  ) {
    menuItems: nodes {
      url
      label
      id
      parentId
    }
  }
}`)

  const [menu, setMenu] = useState(true)

  const toggleHeader = () => {
    setMenu(!menu)
  }
  const logoElement = () => {
    return (
      <Link to="/" onClick={toggleHeader} className="navbar-brand">
        <img src={logo} alt="いちむら" width="80%" />
      </Link>
    )
  }
  const mobileButton = () => {
    return (
      <button
        onClick={toggleHeader}
        className={classTwo}
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </button>
    )
  }

  useEffect(() => {
    const abortController = new AbortController()
    const elementId = document.getElementById('header')
    if (elementId) {
      document.addEventListener(
        'scroll',
        () => {
          if (window.scrollY > 170) {
            elementId.classList.add('is-sticky')
          } else {
            elementId.classList.remove('is-sticky')
          }
        },
        {
          signal: abortController.signal,
        },
      )
    }
    window.scrollTo(0, 0)
    return () => {
      abortController.abort()
    }
  }, [])

  const classOne = menu
    ? 'collapse navbar-collapse'
    : 'collapse navbar-collapse show'
  const classTwo = menu
    ? 'navbar-toggler navbar-toggler-right collapsed'
    : 'navbar-toggler navbar-toggler-right'

  return (
    <header id="header" className="headroom">
      <div className="startp-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            {logoElement()}
            {mobileButton()}
            <div className={classOne} id="navbarSupportedContent">
              <MenuBar
                toggleHeader={toggleHeader}
                treeNodes={buildMenuTree(menuItems)}
              />
            </div>
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
