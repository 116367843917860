import * as React from 'react'
import { PageProps, useStaticQuery, graphql } from 'gatsby'
import urljoin from 'url-join'

import GoTop from '@base/components/app/GoTop'
import Navbar from '@base/components/app/Header'
import Footer from '@base/components/app/Footer'
import { adobeLoader } from './fonts/adobeLoader'

import '@base/css/libraries/bootstrap.min.css'
import 'animate.css'
import '@base/css/libraries/boxicons.min.css'
import '@base/css/libraries/flaticon.css'
import '@base/css/libraries/slick.css'
import 'react-accessible-accordion/dist/fancy-example.css'
import '@base/css/style.scss'

const Layout: React.FC<PageProps> = ({ path, children }) => {
  React.useEffect(() => {
    adobeLoader(document)
  }, [])

  const { site } = useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
    query Layout {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)

  let pageUrl
  if (site?.siteMetadata?.siteUrl && path) {
    pageUrl = urljoin(site?.siteMetadata?.siteUrl, path)
  }

  return (
    <>
      <GoTop scrollStepInPx={100} delayInMs={10.5} />
      <Navbar />
      {children}
      <Footer />
    </>
  )
}

export default Layout