import logo from '@base/images/common/logo.svg'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import * as Icon from 'react-feather'

type FooterItem = {
  label: string
  label2?: string
  url: string
  blank?: boolean // target="_blank"を使用するか(external=true時のみ)
  icon?: JSX.Element
  external?: boolean // gatsby外リンクであるか
}

type FooterList = {
  heading?: string
  hasIcon?: boolean
  information?: FooterItem[]
}

const footerLists: FooterList[][] = [
  [
    {
      heading: '学校法人市邨学園',
      information: [
        {
          label: '名古屋経済大学',
          url: 'https://www.nagoya-ku.ac.jp/',
          blank: true,
          external: true,
        },
        {
          label: '高蔵高等学校/中学校',
          url: 'https://www.takakura-hj.info/',
          blank: true,
          external: true,
        },
        {
          label: '学校法人市邨学園本部',
          url: 'https://ichimura.ac.jp/',
          blank: true,
          external: true,
        },
        {
          label: '名古屋経済大学 市邨幼稚園',
          url: 'https://ichimura.ac.jp/',
          blank: true,
          external: true,
        },
      ],
    },
  ],
  [
    {
      heading: '各種問合せ',
      information: [
        {
          label: 'パンフレット・資料請求',
          url: '/request-docs/',
          external: false,
        },
        {
          label: 'お問い合わせ',
          url: '/contact/',
          external: false,
        },
        {
          label: '法人寄付',
          url: '/donation/corporate/',
          external: false
        },
      ],
    },
    {
      heading: '各種方針',
      information: [
        {
          label: 'プライバシーポリシー',
          url: '/privacy-policy',
        },
        {
          label: 'いじめ防止基本方針',
          url: '/anti-bullying',
        },
        {
          label: 'SDGsの取り組み',
          url: 'https://web-admin.ichimura.ed.jp/wp-content/uploads/2022/11/愛知県SDGs登録制度.pdf',
        },
      ],
    },
  ],
  [
    {
      heading: 'アクセス',
      hasIcon: true,
      information: [
        {
          label: '〒464-8533',
          label2: '名古屋市千種区北千種3-1-37',
          url: 'https://goo.gl/maps/Tf9ojx28FHcXKdt66',
          blank: true,
          external: true,
          icon: <Icon.MapPin />,
        },
        {
          label: '公共交通機関のご案内',
          url: '/access/',
          external: false,
          icon: <Icon.Navigation />,
        },
        {
          label: 'nyugaku@ichimura.ed.jp',
          url: 'mailto:nyugaku@ichimura.ed.jp',
          blank: true,
          external: true,
          icon: <Icon.Mail />,
        },
        {
          label: '052-721-0161',
          url: 'tel:0527210161',
          blank: true,
          external: true,
          icon: <Icon.PhoneCall />,
        },
      ],
    },
  ],
]
const Footer: React.FC = () => {
  return (
    <footer className="footer-area bg-f7fafd">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="single-footer-widget">
              <div className="footer-logo">
                <div className="logo">
                  <Link to="/">
                    <img src={logo} alt="いちむら" />
                  </Link>
                </div>
                <p>生きる、出会う、学ぶ、変わる。</p>
                <StaticImage
                  src="../../images/common/symbol.png"
                  className="symbol"
                  alt=""
                />
              </div>
            </div>
          </div>
          {footerLists.map((footerListCol, colIndex) => {
            return (
              <div className="col-lg-3 col-md-6" key={colIndex}>
                {footerListCol.map((footerList, index) => {
                  return (
                    <div className="single-footer-widget" key={index}>
                      <h3 className="footer-heading">{footerList.heading}</h3>
                      <ul
                        className={
                          footerList.hasIcon ? 'footer-contact-info' : 'list'
                        }
                      >
                        {footerList.information &&
                          footerList.information.map((item, index) => {
                            return (
                              <li key={index}>
                                <ItemLink {...item} />
                              </li>
                            )
                          })}
                      </ul>
                    </div>
                  )
                })}
              </div>
            )
          })}
          <div className="col-lg-12 col-md-12">
            <div className="copyright-area">
              <p>&copy; 名古屋経済大学 市邨中学校・高等学校</p>
            </div>
          </div>
        </div>
      </div>
      <StaticImage
        src="../../images/common/map.png"
        className="map"
        alt="map"
      />
    </footer>
  )
}

const ItemLink: React.FC<FooterItem> = ({
  label,
  label2,
  url,
  external,
  blank,
}) => {
  const content = (
    <>
      {label}
      {label2 && (
        <>
          <br />
          {label2}
        </>
      )}
    </>
  )

  if (!external) {
    return <Link to={url}>{content}</Link>
  }

  return (
    <a href={url} target={blank ? '_blank' : '_self'} rel="noopener noreferrer">
      {content}
    </a>
  )
}

export default Footer
