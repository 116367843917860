exports.components = {
  "component---gatsby-theme-ichimura-base-src-pages-404-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/pages/404.tsx" /* webpackChunkName: "component---gatsby-theme-ichimura-base-src-pages-404-tsx" */),
  "component---gatsby-theme-ichimura-base-src-templates-blog-archive-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/templates/blog-archive.tsx" /* webpackChunkName: "component---gatsby-theme-ichimura-base-src-templates-blog-archive-tsx" */),
  "component---gatsby-theme-ichimura-base-src-templates-blog-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/templates/blog.tsx" /* webpackChunkName: "component---gatsby-theme-ichimura-base-src-templates-blog-tsx" */),
  "component---src-pages-access-index-tsx": () => import("./../../../src/pages/access/index.tsx" /* webpackChunkName: "component---src-pages-access-index-tsx" */),
  "component---src-pages-admissions-high-tsx": () => import("./../../../src/pages/admissions/high.tsx" /* webpackChunkName: "component---src-pages-admissions-high-tsx" */),
  "component---src-pages-admissions-junior-high-tsx": () => import("./../../../src/pages/admissions/junior-high.tsx" /* webpackChunkName: "component---src-pages-admissions-junior-high-tsx" */),
  "component---src-pages-admissions-junior-tsx": () => import("./../../../src/pages/admissions/junior.tsx" /* webpackChunkName: "component---src-pages-admissions-junior-tsx" */),
  "component---src-pages-campus-map-index-tsx": () => import("./../../../src/pages/campus-map/index.tsx" /* webpackChunkName: "component---src-pages-campus-map-index-tsx" */),
  "component---src-pages-complete-index-tsx": () => import("./../../../src/pages/complete/index.tsx" /* webpackChunkName: "component---src-pages-complete-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-donation-corporate-index-tsx": () => import("./../../../src/pages/donation/corporate/index.tsx" /* webpackChunkName: "component---src-pages-donation-corporate-index-tsx" */),
  "component---src-pages-donation-index-tsx": () => import("./../../../src/pages/donation/index.tsx" /* webpackChunkName: "component---src-pages-donation-index-tsx" */),
  "component---src-pages-donation-personal-index-tsx": () => import("./../../../src/pages/donation/personal/index.tsx" /* webpackChunkName: "component---src-pages-donation-personal-index-tsx" */),
  "component---src-pages-educations-high-academic-tsx": () => import("./../../../src/pages/educations/high/academic.tsx" /* webpackChunkName: "component---src-pages-educations-high-academic-tsx" */),
  "component---src-pages-educations-high-bright-tsx": () => import("./../../../src/pages/educations/high/bright.tsx" /* webpackChunkName: "component---src-pages-educations-high-bright-tsx" */),
  "component---src-pages-educations-high-career-tsx": () => import("./../../../src/pages/educations/high/career.tsx" /* webpackChunkName: "component---src-pages-educations-high-career-tsx" */),
  "component---src-pages-educations-high-explorer-tsx": () => import("./../../../src/pages/educations/high/explorer.tsx" /* webpackChunkName: "component---src-pages-educations-high-explorer-tsx" */),
  "component---src-pages-educations-high-index-tsx": () => import("./../../../src/pages/educations/high/index.tsx" /* webpackChunkName: "component---src-pages-educations-high-index-tsx" */),
  "component---src-pages-educations-junior-feature-tsx": () => import("./../../../src/pages/educations/junior/feature.tsx" /* webpackChunkName: "component---src-pages-educations-junior-feature-tsx" */),
  "component---src-pages-educations-junior-high-feature-tsx": () => import("./../../../src/pages/educations/junior-high/feature.tsx" /* webpackChunkName: "component---src-pages-educations-junior-high-feature-tsx" */),
  "component---src-pages-educations-junior-high-index-tsx": () => import("./../../../src/pages/educations/junior-high/index.tsx" /* webpackChunkName: "component---src-pages-educations-junior-high-index-tsx" */),
  "component---src-pages-educations-junior-index-tsx": () => import("./../../../src/pages/educations/junior/index.tsx" /* webpackChunkName: "component---src-pages-educations-junior-index-tsx" */),
  "component---src-pages-history-index-tsx": () => import("./../../../src/pages/history/index.tsx" /* webpackChunkName: "component---src-pages-history-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-learning-tsx": () => import("./../../../src/pages/learning.tsx" /* webpackChunkName: "component---src-pages-learning-tsx" */),
  "component---src-pages-lunch-index-tsx": () => import("./../../../src/pages/lunch/index.tsx" /* webpackChunkName: "component---src-pages-lunch-index-tsx" */),
  "component---src-pages-message-tsx": () => import("./../../../src/pages/message.tsx" /* webpackChunkName: "component---src-pages-message-tsx" */),
  "component---src-pages-method-global-tsx": () => import("./../../../src/pages/method/global.tsx" /* webpackChunkName: "component---src-pages-method-global-tsx" */),
  "component---src-pages-method-ipad-tsx": () => import("./../../../src/pages/method/ipad.tsx" /* webpackChunkName: "component---src-pages-method-ipad-tsx" */),
  "component---src-pages-method-study-tsx": () => import("./../../../src/pages/method/study.tsx" /* webpackChunkName: "component---src-pages-method-study-tsx" */),
  "component---src-pages-request-docs-index-tsx": () => import("./../../../src/pages/request-docs/index.tsx" /* webpackChunkName: "component---src-pages-request-docs-index-tsx" */),
  "component---src-pages-school-song-index-tsx": () => import("./../../../src/pages/school-song/index.tsx" /* webpackChunkName: "component---src-pages-school-song-index-tsx" */),
  "component---src-pages-uniform-tsx": () => import("./../../../src/pages/uniform.tsx" /* webpackChunkName: "component---src-pages-uniform-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-category-archive-tsx": () => import("./../../../src/templates/category-archive.tsx" /* webpackChunkName: "component---src-templates-category-archive-tsx" */),
  "component---src-templates-event-archive-tsx": () => import("./../../../src/templates/event-archive.tsx" /* webpackChunkName: "component---src-templates-event-archive-tsx" */),
  "component---src-templates-event-tsx": () => import("./../../../src/templates/event.tsx" /* webpackChunkName: "component---src-templates-event-tsx" */),
  "component---src-templates-news-archive-tsx": () => import("./../../../src/templates/news-archive.tsx" /* webpackChunkName: "component---src-templates-news-archive-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-past-question-archive-tsx": () => import("./../../../src/templates/past-question-archive.tsx" /* webpackChunkName: "component---src-templates-past-question-archive-tsx" */),
  "component---src-templates-past-question-tsx": () => import("./../../../src/templates/past-question.tsx" /* webpackChunkName: "component---src-templates-past-question-tsx" */),
  "component---src-templates-tag-archive-tsx": () => import("./../../../src/templates/tag-archive.tsx" /* webpackChunkName: "component---src-templates-tag-archive-tsx" */),
  "slice---gatsby-theme-ichimura-base-src-components-post-blog-sidebar-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/components/post/BlogSidebar.tsx" /* webpackChunkName: "slice---gatsby-theme-ichimura-base-src-components-post-blog-sidebar-tsx" */),
  "slice---gatsby-theme-ichimura-base-src-components-post-post-author-tsx": () => import("./../../../../gatsby-theme-ichimura-base/src/components/post/PostAuthor.tsx" /* webpackChunkName: "slice---gatsby-theme-ichimura-base-src-components-post-post-author-tsx" */)
}

